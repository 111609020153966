// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function $$eval(x) {
  if (x === "md") {
    return {
            NAME: "px",
            VAL: 768
          };
  } else if (x === "sm") {
    return {
            NAME: "px",
            VAL: 576
          };
  } else if (x === "xl") {
    return {
            NAME: "px",
            VAL: 1200
          };
  } else if (x === "xxl") {
    return {
            NAME: "px",
            VAL: 1400
          };
  } else {
    return {
            NAME: "px",
            VAL: 992
          };
  }
}

function string_of_px(x) {
  return String(x.VAL) + "px";
}

var Breakpoints_breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};

var Breakpoints = {
  breakpoints: Breakpoints_breakpoints,
  $$eval: $$eval,
  string_of_px: string_of_px
};

function $$eval$1(x) {
  if (x === "light") {
    return {
            NAME: "num",
            VAL: 300
          };
  } else if (x === "medium") {
    return {
            NAME: "num",
            VAL: 500
          };
  } else if (x === "regular") {
    return {
            NAME: "num",
            VAL: 400
          };
  } else {
    return {
            NAME: "num",
            VAL: 600
          };
  }
}

var FontWeight_light = {
  NAME: "num",
  VAL: 300
};

var FontWeight_regular = {
  NAME: "num",
  VAL: 400
};

var FontWeight_medium = {
  NAME: "num",
  VAL: 500
};

var FontWeight_bold = {
  NAME: "num",
  VAL: 600
};

var FontWeight = {
  light: FontWeight_light,
  regular: FontWeight_regular,
  medium: FontWeight_medium,
  bold: FontWeight_bold,
  $$eval: $$eval$1
};

var Color;

exports.Color = Color;
exports.Breakpoints = Breakpoints;
exports.FontWeight = FontWeight;
/* No side effect */
