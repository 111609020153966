// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Units = require("../../uikit/reason/helpers/Units.bs.js");
var View__Jsx3 = require("../../uikit/reason/helpers/View__Jsx3.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var ViewStack = require("../../components/ViewStack").default;

var make = ViewStack;

var JS = {
  make: make
};

function ViewStack$1(props) {
  var __space = props.space;
  var space = __space !== undefined ? __space : "md";
  return JsxRuntime.jsx(make, {
              id: props.id,
              m: View__Jsx3.Space.map(props.m),
              mt: View__Jsx3.Space.map(props.mt),
              mr: View__Jsx3.Space.map(props.mr),
              mb: View__Jsx3.Space.map(props.mb),
              ml: View__Jsx3.Space.map(props.ml),
              mx: View__Jsx3.Space.map(props.mx),
              my: View__Jsx3.Space.map(props.my),
              p: View__Jsx3.Space.map(props.p),
              pt: View__Jsx3.Space.map(props.pt),
              pr: View__Jsx3.Space.map(props.pr),
              pb: View__Jsx3.Space.map(props.pb),
              pl: View__Jsx3.Space.map(props.pl),
              px: View__Jsx3.Space.map(props.px),
              py: View__Jsx3.Space.map(props.py),
              space: View__Jsx3.Space.$$eval(space),
              width: Core__Option.map(props.width, View__Jsx3.Width.$$eval),
              minWidth: Core__Option.map(props.minWidth, View__Jsx3.MinMaxWidthHeight.$$eval),
              maxWidth: Core__Option.map(props.maxWidth, View__Jsx3.MinMaxWidthHeight.$$eval),
              height: Core__Option.map(props.height, View__Jsx3.Width.$$eval),
              minHeight: Core__Option.map(props.minHeight, View__Jsx3.MinMaxWidthHeight.$$eval),
              maxHeight: Core__Option.map(props.maxHeight, View__Jsx3.MinMaxWidthHeight.$$eval),
              alignItems: View__Jsx3.Align.map(props.alignItems),
              justifyContent: View__Jsx3.Justify.map(props.justifyContent),
              flexDirection: View__Jsx3.FlexDirection.map(props.flexDirection),
              flexWrap: View__Jsx3.FlexWrap.map(props.flexWrap),
              flexGrow: props.flexGrow,
              flexShrink: props.flexShrink,
              className: props.className,
              flexBasis: View__Jsx3.FlexBasis.map(props.flexBasis),
              background: props.background,
              backgroundColor: Core__Option.map(props.backgroundColor, Units.hex_to_string),
              borderStyle: Core__Option.map(props.borderStyle, (function (x) {
                      if (x === "initial") {
                        return "initial";
                      } else if (x === "hidden") {
                        return "hidden";
                      } else if (x === "inherit_") {
                        return "inherit";
                      } else if (x === "ridge") {
                        return "ridge";
                      } else if (x === "solid") {
                        return "solid";
                      } else if (x === "outset") {
                        return "outset";
                      } else if (x === "groove") {
                        return "groove";
                      } else if (x === "dashed") {
                        return "dashed";
                      } else if (x === "dotted") {
                        return "dotted";
                      } else if (x === "double") {
                        return "double";
                      } else if (x === "inset") {
                        return "inset";
                      } else {
                        return "none";
                      }
                    })),
              position: Core__Option.map(props.position, (function (x) {
                      if (x === "initial") {
                        return "initial";
                      } else if (x === "inherit_") {
                        return "inherit";
                      } else if (x === "fixed") {
                        return "fixed";
                      } else if (x === "static") {
                        return "static";
                      } else if (x === "relative") {
                        return "relative";
                      } else {
                        return "absolute";
                      }
                    })),
              overflow: Core__Option.map(props.overflow, (function (x) {
                      if (x === "scroll") {
                        return "scroll";
                      } else if (x === "initial") {
                        return "initial";
                      } else if (x === "hidden") {
                        return "hidden";
                      } else if (x === "inherit_") {
                        return "inherit";
                      } else if (x === "visible") {
                        return "visible";
                      } else {
                        return "auto";
                      }
                    })),
              children: props.children
            });
}

var make$1 = ViewStack$1;

exports.JS = JS;
exports.make = make$1;
/* make Not a pure module */
