// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Theme = require("../../../reason/common/Theme/Theme.bs.js");
var Units = require("./Units.bs.js");
var Responsive = require("./Responsive.bs.js");
var View__Jsx3 = require("./View__Jsx3.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Heading = require("../../helpers/Heading").default;

function $$eval(x) {
  if (x === "h2") {
    return "h2";
  } else if (x === "h3") {
    return "h3";
  } else if (x === "h4") {
    return "h4";
  } else if (x === "h5") {
    return "h5";
  } else if (x === "h6") {
    return "h6";
  } else {
    return "h1";
  }
}

var As = {
  $$eval: $$eval
};

function $$eval$1(x) {
  if (typeof x === "object") {
    return Responsive.mapResponsive($$eval$1)(x);
  } else if (x === "left") {
    return "left";
  } else if (x === "right") {
    return "right";
  } else if (x === "matchParent") {
    return "match-parent";
  } else if (x === "justify") {
    return "justify";
  } else if (x === "start") {
    return "start";
  } else if (x === "center") {
    return "center";
  } else if (x === "justifyAll") {
    return "justify-all";
  } else {
    return "end";
  }
}

var TextAlign = {
  $$eval: $$eval$1
};

function $$eval$2(x) {
  if (typeof x === "object") {
    return Responsive.mapResponsive($$eval$2)(x);
  } else if (x === "inherit_") {
    return "inherit";
  } else if (x === "capitalize") {
    return "capitalize";
  } else if (x === "lowercase") {
    return "lowercase";
  } else if (x === "uppercase") {
    return "uppercase";
  } else {
    return "none";
  }
}

var TextTransform = {
  $$eval: $$eval$2
};

function $$eval$3(x) {
  if (typeof x === "object") {
    return Responsive.mapResponsive($$eval$3)(x);
  } else if (x === "normal") {
    return "normal";
  } else {
    return "italic";
  }
}

var FontStyle = {
  $$eval: $$eval$3
};

function $$eval$4(x) {
  var variant = x.NAME;
  if (variant === "em") {
    return x.VAL.toString() + "em";
  } else if (variant === "px") {
    return String(x.VAL) + "px";
  } else if (variant === "num") {
    return String(x.VAL);
  } else if (variant === "rem") {
    return x.VAL.toString() + "rem";
  } else {
    return Responsive.mapResponsive($$eval$4)(x);
  }
}

var FontSize = {
  $$eval: $$eval$4
};

function $$eval$5(_x) {
  while(true) {
    var x = _x;
    if (typeof x === "object") {
      var variant = x.NAME;
      if (variant === "n") {
        return x.VAL.toString();
      } else if (variant === "num") {
        return String(x.VAL);
      } else {
        return Responsive.mapResponsive($$eval$5)(x);
      }
    }
    if (x === "light") {
      _x = Theme.FontWeight.light;
      continue ;
    }
    if (x === "medium") {
      _x = Theme.FontWeight.medium;
      continue ;
    }
    if (x === "regular") {
      _x = Theme.FontWeight.regular;
      continue ;
    }
    _x = Theme.FontWeight.bold;
    continue ;
  };
}

var FontWeight = {
  $$eval: $$eval$5
};

function $$eval$6(x) {
  var variant = x.NAME;
  if (variant === "float" || variant === "num" || variant === "n") {
    return x.VAL.toString();
  } else if (variant === "em") {
    return String(x.VAL) + "em";
  } else if (variant === "px") {
    return String(x.VAL) + "px";
  } else {
    return Responsive.mapResponsive($$eval$6)(x);
  }
}

var LineHeight = {
  $$eval: $$eval$6
};

function $$eval$7(x) {
  if (typeof x === "object") {
    return Responsive.mapResponsive($$eval$7)(x);
  } else if (x === "preLine") {
    return "pre-line";
  } else if (x === "preWrap") {
    return "pre-wrap";
  } else if (x === "breakSpaces") {
    return "break-spaces";
  } else if (x === "normal") {
    return "normal";
  } else if (x === "nowrap") {
    return "nowrap";
  } else {
    return "pre";
  }
}

var WhiteSpace = {
  $$eval: $$eval$7
};

var make = Heading;

var JS = {
  make: make
};

function Heading__Jsx3(props) {
  return JsxRuntime.jsx(make, {
              as: Core__Option.map(props.as_, $$eval),
              id: props.id,
              border: props.border,
              className: props.className,
              p: props.p,
              m: Core__Option.map(props.m, View__Jsx3.Space.$$eval),
              mt: Core__Option.map(props.mt, View__Jsx3.Space.$$eval),
              mb: Core__Option.map(props.mb, View__Jsx3.Space.$$eval),
              ml: Core__Option.map(props.ml, View__Jsx3.Space.$$eval),
              mr: Core__Option.map(props.mr, View__Jsx3.Space.$$eval),
              textAlign: Core__Option.map(props.textAlign, $$eval$1),
              textTransform: Core__Option.map(props.textTransform, $$eval$2),
              fontSize: Core__Option.map(props.fontSize, $$eval$4),
              fontWeight: Core__Option.map(props.fontWeight, $$eval$5),
              lineHeight: Core__Option.map(props.lineHeight, $$eval$6),
              color: Core__Option.map(props.color, Units.hex_to_string),
              style: props.style,
              children: props.children
            });
}

var Space;

var make$1 = Heading__Jsx3;

exports.Space = Space;
exports.As = As;
exports.TextAlign = TextAlign;
exports.TextTransform = TextTransform;
exports.FontStyle = FontStyle;
exports.FontSize = FontSize;
exports.FontWeight = FontWeight;
exports.LineHeight = LineHeight;
exports.WhiteSpace = WhiteSpace;
exports.JS = JS;
exports.make = make$1;
/* make Not a pure module */
