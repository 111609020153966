// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Theme = require("../reason/common/Theme/Theme.bs.js");
var Utils = require("./Utils.bs.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dom_storage = require("rescript/lib/js/dom_storage.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var UseWindowSize = require("uikit/hooks/useWindowSize");
var UseComponentSize = require("uikit/hooks/useComponentSize");

function useComponentSize(liveMeasure, param) {
  var match = UseComponentSize.useComponentSize(liveMeasure !== undefined ? Caml_option.valFromOption(liveMeasure) : undefined);
  return [
          match[0],
          Caml_option.nullable_to_opt(match[1]),
          match[2]
        ];
}

function getBreakpoint(px) {
  var match = Theme.Breakpoints.breakpoints;
  if (px >= match.xxl) {
    return "xxl";
  } else if (px >= match.xl) {
    return "xl";
  } else if (px >= match.lg) {
    return "lg";
  } else if (px >= match.md) {
    return "md";
  } else if (px >= match.sm) {
    return "sm";
  } else {
    return "xs";
  }
}

function useResponsive(xs, sm, md, lg, xl, xxl, param) {
  var windowSize = UseWindowSize.useWindowSize();
  var fallback = function (opt, fallbackVal) {
    if (opt !== undefined) {
      return opt;
    } else {
      return fallbackVal;
    }
  };
  var match = getBreakpoint(windowSize.width);
  return Core__Option.getOr(match === "md" ? fallback(md, sm) : (
                match === "sm" ? sm : (
                    match === "xl" ? fallback(fallback(fallback(xl, lg), md), sm) : (
                        match === "xs" ? Caml_option.some(xs) : (
                            match === "xxl" ? fallback(fallback(fallback(fallback(xxl, xl), lg), md), sm) : fallback(fallback(lg, md), sm)
                          )
                      )
                  )
              ), xs);
}

function useLocalStorage(key, defaultValue) {
  var match = React.useState(function (param) {
        return defaultValue;
      });
  var setValue = match[1];
  React.useEffect((function (param) {
          var value;
          try {
            value = Dom_storage.getItem(key, localStorage);
          }
          catch (exn){
            value = undefined;
          }
          if (value !== undefined) {
            Curry._1(setValue, (function (param) {
                    return value;
                  }));
          }
          
        }), [key]);
  var clearValue = function (param) {
    Curry._1(setValue, (function (param) {
            return "";
          }));
    try {
      return Dom_storage.removeItem(key, localStorage);
    }
    catch (exn){
      return ;
    }
  };
  var setValue$1 = function (value) {
    Curry._1(setValue, (function (param) {
            return value;
          }));
    try {
      return Dom_storage.setItem(key, value, localStorage);
    }
    catch (exn){
      return ;
    }
  };
  return [
          match[0],
          setValue$1,
          clearValue
        ];
}

function useSetInterval(handler, delay) {
  var savedHandler = React.useRef(undefined);
  React.useEffect((function (param) {
          Utils.React.setCurrentRef(savedHandler, handler);
        }), [handler]);
  React.useEffect((function (param) {
          var id = setInterval((function (param) {
                  var handler = savedHandler.current;
                  if (handler !== undefined) {
                    return Curry._1(handler, undefined);
                  }
                  
                }), delay);
          return (function (param) {
                    clearInterval(id);
                  });
        }), [
        delay,
        savedHandler
      ]);
}

function useElementRef(param) {
  var match = React.useState(function (param) {
        
      });
  var setValue = match[1];
  var setRef = function (e) {
    Curry._1(setValue, (function (param) {
            if (e == null) {
              return ;
            } else {
              return Caml_option.some(e);
            }
          }));
  };
  return [
          match[0],
          setRef
        ];
}

var _map = {"scroll":"scroll"};

var _revMap = {"scroll":"scroll"};

function eventTypeToJs(param) {
  return _map[param];
}

function eventTypeFromJs(param) {
  return _revMap[param];
}

function useEventListenerOnDocument(useCaptureOpt, handler, $$event) {
  var useCapture = useCaptureOpt !== undefined ? useCaptureOpt : false;
  var savedHandler = React.useRef(undefined);
  React.useEffect((function (param) {
          Utils.React.setCurrentRef(savedHandler, handler);
        }), [handler]);
  React.useEffect((function (param) {
          var onEvent = function ($$event) {
            var handler = savedHandler.current;
            if (handler !== undefined) {
              return Curry._1(handler, $$event);
            }
            
          };
          var $$event$1 = eventTypeToJs($$event);
          if (useCapture) {
            document.addEventListener($$event$1, onEvent, true);
          } else {
            document.addEventListener($$event$1, onEvent);
          }
          return (function (param) {
                    document.removeEventListener($$event$1, onEvent, true);
                  });
        }), [
        $$event,
        savedHandler,
        useCapture
      ]);
}

function usePrevious(value) {
  var ref = React.useRef(value);
  React.useEffect(function (param) {
        ref.current = value;
      });
  return ref.current;
}

exports.useComponentSize = useComponentSize;
exports.getBreakpoint = getBreakpoint;
exports.useResponsive = useResponsive;
exports.useLocalStorage = useLocalStorage;
exports.useSetInterval = useSetInterval;
exports.useElementRef = useElementRef;
exports.eventTypeToJs = eventTypeToJs;
exports.eventTypeFromJs = eventTypeFromJs;
exports.useEventListenerOnDocument = useEventListenerOnDocument;
exports.usePrevious = usePrevious;
/* Utils Not a pure module */
