import { useState, useCallback, useLayoutEffect, useEffect } from 'react'

function getDimensionObject(node) {
  const rect = node.getBoundingClientRect()
  const bodyRect = document.body.getBoundingClientRect()
  const offsetTop = rect.top - bodyRect.top
  const offsetLeft = rect.left - bodyRect.left
  const offsetRight = rect.right - bodyRect.right
  const offsetBottom = rect.bottom - bodyRect.bottom
  return rect.width && (rect.x || rect.left || rect.right)
    ? {
        offsetTop,
        offsetLeft,
        offsetRight,
        offsetBottom,
        width: rect.width,
        height: rect.height,
        x: 'x' in rect ? rect.x : rect.left || rect.right - rect.width,
        y: 'y' in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom,
      }
    : undefined
}

export function useComponentSize(liveMeasure = true, _) {
  const [dimensions, setDimensions] = useState(undefined)
  const [node, setNode] = useState(null)

  const ref = useCallback(callbackNode => {
    setNode(callbackNode)
  }, [])

  // React warns when useLayoutEffect is used in the server. This is because one use case of
  // useLayoutEffect is when it's essential to run it so that the UI doesn't look broken,
  // so React wants to prevent the developer from shipping a broken HTML from the server.
  // This however is not the case everywhere we use this hook, so for now we're avoiding the hook
  // on the server. We can change this if a usage of this hook becomes essential for the initial HTML.
  const effectHook = typeof window !== 'undefined' ? useLayoutEffect : useEffect

  // eslint-disable-next-line consistent-return
  effectHook(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)))
      measure()

      if (liveMeasure) {
        window.addEventListener('resize', measure)
        window.addEventListener('scroll', measure)

        return () => {
          window.removeEventListener('resize', measure)
          window.removeEventListener('scroll', measure)
        }
      }
    }
  }, [node])

  return [ref, dimensions, node]
}
