// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Units = require("../../../uikit/reason/helpers/Units.bs.js");

function setAlpha(alpha, color) {
  var withoutAlpha = color.VAL.substring(0, 6);
  return {
          NAME: "hex",
          VAL: withoutAlpha + alpha
        };
}

var toString = Units.hex_to_string;

var white = {
  NAME: "hex",
  VAL: "ffffff"
};

var black = {
  NAME: "hex",
  VAL: "000000"
};

var alabaster = {
  NAME: "hex",
  VAL: "fafafa"
};

var alabasterAlt = {
  NAME: "hex",
  VAL: "fafafa"
};

var wildSand = {
  NAME: "hex",
  VAL: "f4f4f5"
};

var gallery = {
  NAME: "hex",
  VAL: "e4e4e7"
};

var alto = {
  NAME: "hex",
  VAL: "d4d4d8"
};

var silverSand = {
  NAME: "hex",
  VAL: "a1a1aa"
};

var silver = {
  NAME: "hex",
  VAL: "a1a1aa"
};

var gray = {
  NAME: "hex",
  VAL: "71717a"
};

var jumbo = {
  NAME: "hex",
  VAL: "71717a"
};

var doveGray = {
  NAME: "hex",
  VAL: "52525b"
};

var chicago = {
  NAME: "hex",
  VAL: "52525b"
};

var mineShaft = {
  NAME: "hex",
  VAL: "3f3f46"
};

var tuatara = {
  NAME: "hex",
  VAL: "3f3f46"
};

var oldLace = {
  NAME: "hex",
  VAL: "fdfaec"
};

var citrineWhite = {
  NAME: "hex",
  VAL: "f9f2d1"
};

var chino = {
  NAME: "hex",
  VAL: "d2ccb0"
};

var driftwood = {
  NAME: "hex",
  VAL: "a88542"
};

var tussock = {
  NAME: "hex",
  VAL: "c99f50"
};

var goldTips = {
  NAME: "hex",
  VAL: "e9be14"
};

var galliano = {
  NAME: "hex",
  VAL: "dfbc0c"
};

var darkGoldenrod = {
  NAME: "hex",
  VAL: "b68f11"
};

var aquaHaze = {
  NAME: "hex",
  VAL: "e9eff3"
};

var mystic = {
  NAME: "hex",
  VAL: "e3ebf0"
};

var wedgewood = {
  NAME: "hex",
  VAL: "44799b"
};

var calypso = {
  NAME: "hex",
  VAL: "2c6585"
};

var stTropaz = {
  NAME: "hex",
  VAL: "28618d"
};

var astral = {
  NAME: "hex",
  VAL: "2f71a5"
};

var biscay = {
  NAME: "hex",
  VAL: "1e4a6b"
};

var bigStone = {
  NAME: "hex",
  VAL: "1b3549"
};

var bigStoneAlt = {
  NAME: "hex",
  VAL: "162b3b"
};

var bigStoneDark = {
  NAME: "hex",
  VAL: "10202c"
};

var peppermint = {
  NAME: "hex",
  VAL: "eaf4e4"
};

var chelseaCucumber = {
  NAME: "hex",
  VAL: "74b34c"
};

var apple = {
  NAME: "hex",
  VAL: "61a03a"
};

var vidaLoca = {
  NAME: "hex",
  VAL: "46861f"
};

var crimson = {
  NAME: "hex",
  VAL: "dc1a43"
};

var monza = {
  NAME: "hex",
  VAL: "c20031"
};

var carmine = {
  NAME: "hex",
  VAL: "a3001c"
};

var cherub = {
  NAME: "hex",
  VAL: "fadde3"
};

var whiteLinen = {
  NAME: "hex",
  VAL: "f7f1e5"
};

var facebook = {
  NAME: "hex",
  VAL: "3b5998"
};

var twitter = {
  NAME: "hex",
  VAL: "1da1f2"
};

var instagram = {
  NAME: "hex",
  VAL: "e1306c"
};

var yelp = {
  NAME: "hex",
  VAL: "af0606"
};

var linkedin = {
  NAME: "hex",
  VAL: "0077b5"
};

var youtube = {
  NAME: "hex",
  VAL: "cd201f"
};

var pinterest = {
  NAME: "hex",
  VAL: "bd081c"
};

var Vars;

exports.toString = toString;
exports.setAlpha = setAlpha;
exports.white = white;
exports.black = black;
exports.alabaster = alabaster;
exports.alabasterAlt = alabasterAlt;
exports.wildSand = wildSand;
exports.gallery = gallery;
exports.alto = alto;
exports.silverSand = silverSand;
exports.silver = silver;
exports.gray = gray;
exports.jumbo = jumbo;
exports.doveGray = doveGray;
exports.chicago = chicago;
exports.mineShaft = mineShaft;
exports.tuatara = tuatara;
exports.oldLace = oldLace;
exports.citrineWhite = citrineWhite;
exports.chino = chino;
exports.driftwood = driftwood;
exports.tussock = tussock;
exports.goldTips = goldTips;
exports.galliano = galliano;
exports.darkGoldenrod = darkGoldenrod;
exports.aquaHaze = aquaHaze;
exports.mystic = mystic;
exports.wedgewood = wedgewood;
exports.calypso = calypso;
exports.stTropaz = stTropaz;
exports.astral = astral;
exports.biscay = biscay;
exports.bigStone = bigStone;
exports.bigStoneAlt = bigStoneAlt;
exports.bigStoneDark = bigStoneDark;
exports.peppermint = peppermint;
exports.chelseaCucumber = chelseaCucumber;
exports.apple = apple;
exports.vidaLoca = vidaLoca;
exports.crimson = crimson;
exports.monza = monza;
exports.carmine = carmine;
exports.cherub = cherub;
exports.whiteLinen = whiteLinen;
exports.facebook = facebook;
exports.twitter = twitter;
exports.instagram = instagram;
exports.yelp = yelp;
exports.linkedin = linkedin;
exports.youtube = youtube;
exports.pinterest = pinterest;
exports.Vars = Vars;
/* No side effect */
