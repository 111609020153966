import * as React from 'react'
import { makeView } from 'uikit/helpers/View'
import styled from 'styled-components'
import { useResponsive } from 'utils/Hooks.bs'

const noWrapStyles = props =>
  `
  align-items: flex-start;
  & > *:not(:last-child) {
    ${props.flexDirection === 'row' ? 'margin-right: ' : 'margin-bottom: '} ${props.space};
  }`
const wrapStyles = props =>
  `
  align-items: flex-start;
  margin-left: -${props.space};
  margin-right: -${props.space};
  & > div,
  & > button {
    ${props.flexDirection === 'row' ? 'margin-left: ' : 'margin-bottom: '} ${props.space};
    ${props.flexDirection === 'row' ? `margin-bottom: ${props.space};` : ''};
  }`

const StyledStack = styled('div')`
  ${props => (props.flexWrap === 'wrap' ? wrapStyles(props) : noWrapStyles(props))};
`
const View = makeView(StyledStack)

// This is a bit of a hack, but we need to resolve responsive values here to be able to adapt the styling.
function useResolvedResponsiveVal(thing) {
  return typeof thing === 'object'
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useResponsive(thing.xs, thing.sm, thing.md, thing.lg, thing.xl, thing.xxl, 0)
    : thing
}
export default function ViewStack({ space, flexDirection, ...props }) {
  const space_ = useResolvedResponsiveVal(space)
  const direction_ = useResolvedResponsiveVal(flexDirection)
  return <View space={space_} flexDirection={direction_} {...props} />
}
