// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Heading__Jsx3 = require("./Heading__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var $$Text = require("../../helpers/Text");
var $$Text$1 = require("../../helpers/Text").default;

function $$eval(x) {
  if (x === "p") {
    return "p";
  } else if (x === "h3") {
    return "h3";
  } else if (x === "div") {
    return "div";
  } else {
    return "span";
  }
}

var As = {
  $$eval: $$eval
};

var make = $$Text$1;

var JS = {
  make: make
};

function Text__Jsx3$Text(props) {
  return JsxRuntime.jsx(make, {
              as: Core__Option.map(props.as_, $$eval),
              fontSize: Core__Option.map(props.fontSize, Heading__Jsx3.FontSize.$$eval),
              lineHeight: Core__Option.map(props.lineHeight, Heading__Jsx3.LineHeight.$$eval),
              whiteSpace: Core__Option.map(props.whiteSpace, Heading__Jsx3.WhiteSpace.$$eval),
              textAlign: Core__Option.map(props.textAlign, Heading__Jsx3.TextAlign.$$eval),
              textTransform: Core__Option.map(props.textTransform, Heading__Jsx3.TextTransform.$$eval),
              fontWeight: Core__Option.map(props.fontWeight, Heading__Jsx3.FontWeight.$$eval),
              fontStyle: Core__Option.map(props.fontStyle, Heading__Jsx3.FontStyle.$$eval),
              color: props.color,
              lineClamp: props.lineClamp,
              className: props.className,
              children: props.children
            });
}

var $$Text$2 = {
  JS: JS,
  make: Text__Jsx3$Text
};

var make$1 = $$Text.TextWithOverflow;

var JS$1 = {
  make: make$1
};

function Text__Jsx3$WithOverflow(props) {
  return JsxRuntime.jsx(make$1, {
              as: Core__Option.map(props.as_, $$eval),
              fontSize: Core__Option.map(props.fontSize, Heading__Jsx3.FontSize.$$eval),
              lineHeight: Core__Option.map(props.lineHeight, Heading__Jsx3.LineHeight.$$eval),
              whiteSpace: Core__Option.map(props.whiteSpace, Heading__Jsx3.WhiteSpace.$$eval),
              textAlign: Core__Option.map(props.textAlign, Heading__Jsx3.TextAlign.$$eval),
              textTransform: Core__Option.map(props.textTransform, Heading__Jsx3.TextTransform.$$eval),
              fontWeight: Core__Option.map(props.fontWeight, Heading__Jsx3.FontWeight.$$eval),
              fontStyle: Core__Option.map(props.fontStyle, Heading__Jsx3.FontStyle.$$eval),
              color: props.color,
              lineClamp: props.lineClamp,
              className: props.className,
              hasOverflowCallback: props.hasOverflowCallback,
              children: props.children
            });
}

var WithOverflow = {
  JS: JS$1,
  make: Text__Jsx3$WithOverflow
};

var make$2 = Text__Jsx3$Text;

exports.As = As;
exports.$$Text = $$Text$2;
exports.WithOverflow = WithOverflow;
exports.make = make$2;
/* make Not a pure module */
